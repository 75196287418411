export function maskCnpj(v: string) {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');
  v = v.length > 18 ? v.substring(0, 18) : v;
  return v;
}

export function cpnjNumber(v: string) {
  return v.replace(/\D/g, '');
}

export function maskPhone(v: string) {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/, '($1) $2');
  v = v.length > 15 ? v.substring(0, 15) : v;
  return v;
}
