import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Button from '../components/Button';
import Container from '../components/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';

export default function Cookies() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  function goToHome() {
    window.location.href = '/';
  }

  return (
    <>
      <Header />
      <Container background='white' className='px-4 md:px-0'>
        <Container className='md:grid md:grid-cols-5 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
          <Button active title='Voltar' hasIcon={false} onClick={goToHome} />
        </Container>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4'>
          <Title color='green' className='text-center md:text-start py-4' data-aos='fade-right' data-aos-duration='500'>
            Política de Cookies
          </Title>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1000'
        >
          <SubTitle className='font-bold'>1. Introdução</SubTitle>
          <SubTitle className='pt-2'>
            Esta Política de Cookies explica como a Amplar Gestão Sustentável (&quot;nós&quot;, &quot;nosso&quot; ou
            &quot;empresa&quot;) utiliza cookies e tecnologias semelhantes quando você acessa e utiliza nossa página de
            consulta de licenças ambientais <u>licenciamento.amplargs.com.br</u>.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1500'
        >
          <SubTitle className='font-bold'>2. O que são Cookies?</SubTitle>
          <SubTitle className='pt-2'>
            Cookies são pequenos arquivos de texto armazenados no seu dispositivo (computador, smartphone, tablet)
            quando você visita uma página da web. Eles são amplamente utilizados para fazer com que as páginas funcionem
            de forma mais eficiente, além de fornecer informações aos proprietários da página.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>3. Tipos de Cookies que utilizamos</SubTitle>
          <SubTitle className='pt-2'>
            Utilizamos os seguintes tipos de cookies:
            <ul>
              <li>
                <b>Cookies Necessários</b>: Essenciais para que a Página funcione corretamente. Sem estes cookies, a
                Página não pode ser utilizada como pretendido.
              </li>
              <li>
                <b>Cookies de Desempenho</b>: Coletam informações sobre como os usuários utilizam a Página, como as
                páginas que são visitadas com mais frequência. Estas informações são usadas para melhorar a
                funcionalidade da Página.
              </li>
              <li>
                <b>Cookies Funcionais</b>: Permitem que a Página se lembre das escolhas que você faz (como seu nome de
                usuário, idioma ou região) e forneça recursos aprimorados e mais personalizados.
              </li>
              <li>
                <b>Cookies de Publicidade</b>: Utilizados para exibir anúncios relevantes para você e seus interesses.
                Eles também são usados para limitar o número de vezes que você vê um anúncio, além de ajudar a medir a
                eficácia das campanhas publicitárias.
              </li>
            </ul>
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2500'
        >
          <SubTitle className='font-bold'>4. Como Utilizamos os Cookies</SubTitle>
          <SubTitle className='pt-2'>
            Utilizamos cookies para: melhorar a funcionalidade e desempenho da Página, analisar o tráfego e uso da
            Página para melhorar a experiência do usuário e fornecer conteúdos e anúncios personalizados.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>5. Gerenciamento de Cookies</SubTitle>
          <SubTitle className='pt-2'>
            Você pode controlar e/ou excluir cookies a qualquer momento. A maioria dos navegadores permite que você
            gerencie suas preferências de cookies. No entanto, desativar cookies pode afetar a funcionalidade da Página
            e a qualidade dos serviços que fornecemos.
            <br />
            Para saber mais sobre como gerenciar cookies no seu navegador, visite as configurações de ajuda do seu
            navegador.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>6. Alterações na Política de Cookies</SubTitle>
          <SubTitle className='pt-2'>
            Podemos atualizar esta Política de Cookies periodicamente. Quaisquer alterações serão publicadas nesta
            Página, e é de sua responsabilidade revisar esta política regularmente para estar ciente de quaisquer
            modificações.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>7. Contato</SubTitle>
          <SubTitle className='pt-2'>
            Para perguntas ou preocupações sobre esta Política de Cookies, entre em contato conosco pelo e-mail
            contato@amplargs.com.br ou pelo telefone (35) 3414-9913. <br />
            Estas políticas foram atualizadas pela última vez em 5 de junho de 2024.
          </SubTitle>
        </Container>
      </Container>
      <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
        <Button active title='CLIQUE AQUI PARA REALIZAR A CONSULTA' hasIcon={false} onClick={goToHome} />
      </Container>
      <Footer />
    </>
  );
}
