import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Button from '../components/Button';
import Container from '../components/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';

export default function Privacidade() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  function goToHome() {
    window.location.href = '/';
  }

  return (
    <>
      <Header />
      <Container background='white' className='px-4 md:px-0'>
        <Container className='md:grid md:grid-cols-5 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
          <Button active title='Voltar' hasIcon={false} onClick={goToHome} />
        </Container>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4'>
          <Title color='green' className='text-center md:text-start py-4' data-aos='fade-right' data-aos-duration='500'>
            Política de Privacidade
          </Title>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1000'
        >
          <SubTitle className='font-bold'>1. Introdução</SubTitle>
          <SubTitle className='pt-2'>
            Esta Política de Privacidade descreve como a Amplar Gestão Sustentável (&quot;nós&quot;, &quot;nosso&quot;
            ou &quot;empresa&quot;) coleta, usa, armazena e protege as informações pessoais dos usuários
            (&quot;você&quot;) que acessam e utilizam nossa página de consulta de licenças ambientais
            <u>licenciamento.amplargs.com.br</u>.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1500'
        >
          <SubTitle className='font-bold'>2. Informações que Coletamos</SubTitle>
          <SubTitle className='pt-2'>
            Podemos coletar os seguintes tipos de informações pessoais: informações de contato: nome, endereço de
            e-mail, número de telefone; informações de uso: dados sobre como você utiliza a Página, incluindo endereço
            IP, tipo de navegador, idioma do navegador, páginas visitadas e tempo gasto nas páginas.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>3. Uso das Informações</SubTitle>
          <SubTitle className='pt-2'>
            As informações coletadas são utilizadas para: fornecer e melhorar nossos serviços, entrar em contato com
            você para oferecer serviços relacionados à licença ambiental, enviar notificações sobre o status de suas
            licenças ambientais, analisar o uso da Página para melhorar a experiência do usuário.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2500'
        >
          <SubTitle className='font-bold'>4. Compartilhamento de Informações</SubTitle>
          <SubTitle className='pt-2'>
            Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias: com
            provedores de serviços que auxiliam na operação da Página e que estão sujeitos a obrigações de
            confidencialidade; quando exigido por lei ou para proteger nossos direitos legais.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>5. Segurança das Informações</SubTitle>
          <SubTitle className='pt-2'>
            Implementamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso não
            autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou
            armazenamento eletrônico é totalmente seguro, e não podemos garantir a segurança absoluta das informações
            transmitidas ou armazenadas.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>6. Retenção de Dados</SubTitle>
          <SubTitle className='pt-2'>
            Reteremos suas informações pessoais apenas pelo tempo necessário para cumprir os propósitos para os quais
            foram coletadas, incluindo para satisfazer quaisquer requisitos legais, contábeis ou de relatório.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>7. Seus Direitos</SubTitle>
          <SubTitle className='pt-2'>
            Você tem o direito de:
            <ul>
              <li>Acessar suas informações pessoais.</li>
              <li>Solicitar a correção de informações pessoais incorretas ou desatualizadas.</li>
              <li>Solicitar a exclusão de suas informações pessoais.</li>
              <li>Optar por não receber comunicações de marketing.</li>
            </ul>
            Para exercer qualquer um desses direitos, entre em contato conosco pelo e-mail contato@amplargs.com.br ou
            pelo telefone (35) 3414-9913.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>8. Alterações na Política de Privacidade</SubTitle>
          <SubTitle className='pt-2'>
            Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão publicadas nesta
            Página, e é de sua responsabilidade revisar esta política regularmente para estar ciente de quaisquer
            modificações.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>9. Contato</SubTitle>
          <SubTitle className='pt-2'>
            Para perguntas ou preocupações sobre esta Política de Privacidade, entre em contato conosco pelo e-mail
            contato@amplargs.com.br ou pelo telefone (35) 3414-9913.
          </SubTitle>
        </Container>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
          <Button active title='CLIQUE AQUI PARA REALIZAR A CONSULTA' hasIcon={false} onClick={goToHome} />
        </Container>
      </Container>
      <Footer />
    </>
  );
}
