export const SERVER_URL = 'https://licenciamento.amplargs.com.br/api';
export const KEY = 'cgc';
export const TERMS_POLITICA_PRIVACIDADE = 'privacidade';
export const TERMS_COOKIES = 'cookies';
export const TERMS_USO = 'termos';
export const STATES = [{ value: 'SP', label: 'São Paulo' }];
export const ABREVIATIONS: { [key: string]: string } = {
  'licença de operação': 'LO',
  'licença operação': 'LO',
  'licença de instalação': 'LI',
  'licença instalação': 'LI',
  'licença prévia': 'LP',
  'certificado de movimentação de resíduos de interesse ambiental (cadri)': 'CADRI',
  'licença prévia e de instalação': 'LPI',
  'licença prévia e instalação': 'LPI',
  'cert de dispensa de licença': 'CDL',
  'certificado de dispensa de licença': 'CDL',
  'certificado de dispensa de licença ambiental': 'CDL',
  'certificado dispensa licença': 'CDL',
  'certificado dispensa licença ambiental': 'CDL',
  'certificado de dispensa de licença (cdl)': 'CDL',
  'parecer técnico': 'PT',
  'cert mov residuos int amb': 'CADRI',
  'alteração de documento': 'ALT',
  'licença de operação corretiva': 'LO',
  'licença de instalação de loteamento': 'LI',
  'licença de instalação loteamento': 'LI',
  'licença instalação loteamento': 'LI',
  'licença prévia de loteamento': 'LP',
  'licença de operação de loteamento': 'LO',
  'licença de operação loteamento': 'LO',
  'licença operação loteamento': 'LO',
  'parecer desfavorável': 'PD',
  'l.i. sist. dest. res. no solo': 'LI',
  'licença prévia, de instalação e de operação': 'LPIO',
  'licença prévia, instalação e operação': 'LPIO',
  'licença prévia instalação e operação': 'LPIO',
  'licença prévia instalação operação': 'LPIO',
  'licença de operação (renovação simplificada)': 'LO',
  'licença de operação (renovação)': 'LO',
  'licença de operação a título precário': 'LO',
  'licença de operação título precário': 'LO',
  'licença de operação a título precário (renovação)': 'LO',
  'licença de operação parcial': 'LO',
  'licença operação parcial': 'LO',
  'licença de instalação de desmembramento': 'LI',
  'licença de instalação desmembramento': 'LI',
  'licença instalação desmembramento': 'LI',
  'licença prévia loteamento': 'LP',
  'licença prévia de desmembramento': 'LP',
  'licença prévia desmembramento': 'LP',
  'licença de operação de desmembramento': 'LO',
  'licença de operação desmembramento': 'LO',
  'licença operação desmembramento': 'LO',
  'certificado de enquadramento de estabelecimento industrial (id)': 'CEEI',
  'certificado de enquadramento de estabelecimento industrial': 'CEEI',
  'certificado de enquadramento de estabelecimento industrial (cee)': 'CEEI',
  'certificado de enquadramento de estabelecimento industrial (ceei)': 'CEEI',
  'certificado enquadramento estabelecimento industrial': 'CEEI',
  'parecer técnico de autorização para recebimento de resíduos industriais': 'PT',
  'licença de operação de aterro sanitário': 'LO',
  'parecer técnico de autorização para recebimento de resíduos industriais empresa outra uf': 'PT',
  'treinamento de combate a incêndio': 'TCI',
  'treinamento combate a incêndio': 'TCI',
  'treinamento de combate a incêndio (tci)': 'TCI',
  'treinamento combate a incêndio (tci)': 'TCI',
  'declaração de atividade isenta de licenciamento': 'DAIL',
  'declaração de atividade isenta de licenciamento (dail)': 'DAIL',
  'licença de operação de posto de combustível': 'LO',
  'autorização emitida pelo vra': 'VRA',
  'autorização emitida pelo vra (vra)': 'VRA',
  'auto de infração - multa': 'AI',
  'auto de infração multa - diária': 'AI',
  'auto de infração multa diária': 'AI',
  'auto de infração - advertência': 'AI',
  'auto de infração advertência': 'AI',
  'auto de infração - embargo': 'AI',
  'auto de infração embargo': 'AI',
  'auto de infração - multa - diária': 'AI',
  'lp/li de loteamento': 'LPI',
  'lp/li de desmembramento': 'LPI',
  'lp/li de desdobro': 'LPI',
  'licença opera. desmembramento': 'LO',
  'licença opera. desdobro': 'LO',
  'licença opera. loteamento': 'LO',
  'licença opera. de loteamento': 'LO',
  'licença opera. de desmembramento': 'LO',
  'licença opera. de desdobro': 'LO',
  'licença operacão de loteamento': 'LO',
  'licença operacão de desmembramento': 'LO',
};
