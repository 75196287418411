import React from 'react';

console.log(React.version);

export default function Header() {
  return (
    <header className='flex bg-secondary-100 h-20 items-center px-10'>
      <img
        src='../assets/img/amplarlogo.png'
        alt='Amplar Logo'
        className='h-16 cursor-pointer'
        onClick={() => (window.location.href = '/')}
      />
    </header>
  );
}
