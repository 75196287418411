import clsx from 'clsx';
import React from 'react';

console.log(React.version);

interface SubTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

export default function SubTitle({ children, ...props }: SubTitleProps) {
  const { className, ...rest } = props;

  return (
    <h2 className={clsx('text-sm text-secondary-800', className)} {...rest}>
      {children}
    </h2>
  );
}
