import clsx from 'clsx';
import React from 'react';

console.log(React.version);

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  color?: 'green' | 'white';
}

export default function Title({ children, color, ...props }: TitleProps) {
  const { className, ...rest } = props;

  return (
    <h1
      className={clsx(
        'text-3xl font-bold text-primary-400',
        color && color === 'green' ? 'text-primary-400' : 'text-white',
        className,
      )}
      {...rest}
    >
      {children}
    </h1>
  );
}
