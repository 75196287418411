import clsx from 'clsx';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

console.log(React.version);

interface ArrowDownButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function ArrowDownButton({ ...rest }: ArrowDownButtonProps) {
  const { className, ...props } = rest;
  return (
    <button
      {...props}
      className={clsx('p-5 bg-primary-400 text-white rounded-full hover:scale-105 hover:transition-all', className)}
    >
      <FaChevronDown className='text-2xl' />
    </button>
  );
}
