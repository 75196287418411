import clsx from 'clsx';
import React from 'react';

console.log(React.version);

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType: 'small' | 'large';
}

export default function Input({ inputType, ...props }: InputProps) {
  const { className, ...rest } = props;
  return (
    <input
      {...rest}
      className={clsx(
        'border-secondary-800 text-center w-full bg-secondary-100 outline-secondary-400',
        inputType === 'small' ? 'rounded-xl h-10 text-sm' : 'rounded-lg h-16 text-xl',
        className,
      )}
    />
  );
}
