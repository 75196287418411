import clsx from 'clsx';
import React from 'react';
import SubTitle from './SubTitle';

console.log(React.version);

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: 'conformidade' | 'transparencia' | 'seguranca' | 'identificacao' | 'melhoria' | 'vantagem' | 'apoio';
  title: string;
  subtitle: string;
}

export default function Card({ image, title, subtitle, ...props }: CardProps) {
  const { className, ...rest } = props;

  return (
    <div className={clsx('md:w-1/3 sm:w-full flex h-48 gap-4 p-4', className)} {...rest} data-aos='zoom-in'>
      <div className='w-1/4'>
        <img className='w-1 px-4' src={`../assets/img/cards/${image}.png`} alt={title} />
      </div>
      <div className='flex gap-4 flex-col w-3/4'>
        <SubTitle className='font-bold text-start'>{title}</SubTitle>
        <SubTitle className='text-start pt-2 md:pt-0'>{subtitle}</SubTitle>
      </div>
    </div>
  );
}
