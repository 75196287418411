import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Loading from '../pages/Loading';
import Result from '../pages/Result';
import Privacidade from '../pages/Privacidade';
import Termos from '../pages/Termos';
import Cookies from '../pages/Cookies';

console.log(React.version);

const ListRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/loading' element={<Loading />} />
      <Route path='/result' element={<Result />} />
      <Route path='/privacidade' element={<Privacidade />} />
      <Route path='/termos' element={<Termos />} />
      <Route path='/cookies' element={<Cookies />} />
    </Routes>
  );
};

export default ListRoutes;
