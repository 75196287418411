import clsx from 'clsx';
import React from 'react';
import Container from '../components/Container';
import Title from '../components/Title';
import { KEY, SERVER_URL } from '../utils/consts';
import { cpnjNumber } from '../utils/masks';
import { License, LicensesResponse } from '../utils/types';

const messages = [
  'Realizando consulta, por favor aguarde...',
  'Consultando dados...',
  'Aguarde um momento...',
  'Estamos quase lá ...',
  'Estamos quase terminando ...',
  'Quase lá ...',
  'Quase terminando ...',
  'Estamos finalizando ...',
  'Finalizando ...',
];

export default function Loading() {
  const cnpj = localStorage.getItem('cnpj') || '';
  const state = localStorage.getItem('state') || '';
  const [message, setMessage] = React.useState(messages[0]);
  const [count, setCount] = React.useState(0);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCount(count >= 10 ? 0 : count + 1);
      if (count === 10) {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setMessage(messages[randomIndex]);
      }
      setChanged(!changed);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  });

  React.useEffect(() => {
    if (!cnpj) {
      alert('CNPJ não encontrado! informe o CNPJ novamente.');
      window.location.href = '/';
    }
    fetchCNPJ();
  }, []);

  function handleError(error: string | unknown) {
    console.error(error);
    localStorage.removeItem('cnpj');
    localStorage.removeItem('reason');
    localStorage.removeItem('state');
    alert(error);
    window.location.href = '/';
  }

  async function fetchCNPJ() {
    console.log('Fetching CNPJ...');
    const data: { cnpj: string; licenses: License[] } = JSON.parse(localStorage?.getItem('data') ?? '[]');
    if (data.cnpj === cnpj) {
      window.location.href = '/result';
      return;
    }
    localStorage.removeItem('data');
    await fetch(`${SERVER_URL}/licenses?value=${cpnjNumber(cnpj)}?&key=${KEY}&state=${state}`)
      .then(async data => {
        if (!data.ok) {
          throw new Error('Tempo de resposta excedido, tente novamente mais tarde.');
        }
        try {
          const json = await data.json();
          return json;
        } catch (error) {
          throw new Error('Tempo de resposta excedido, tente novamente mais tarde.');
        }
      })
      .then(async (res: LicensesResponse) => {
        if (res.status === 'error') {
          handleError(res.message);
          return;
        }
        const licenses = res.licenses || [];
        if (!licenses.length) {
          handleError(
            'Nenhuma licença foi encontrada para o CNPJ informado, mas não se preocupe, pode ser que a empresa possua um certificado de dispensa de licença ambiental! Entre em contato conosco para mais informações.',
          );
          return;
        }
        const data: { cnpj: string; licenses: License[] } = { cnpj: cnpj, licenses: licenses };
        window.localStorage.setItem('data', JSON.stringify(data));
        window.location.href = '/result';
      })
      .catch(error => handleError(error));
  }
  const Dots = React.useMemo(() => {
    return (
      <div className='mt-4 flex'>
        <div
          className={clsx(
            'w-2 h-2 rounded-full mx-2 animate-bounce',
            changed ? 'bg-secondary-400' : 'bg-secondary-100',
          )}
        />
        <div
          className={clsx(
            'w-2 h-2 rounded-full mx-2 animate-bounce',
            !changed ? 'bg-secondary-400' : 'bg-secondary-100',
          )}
        />
      </div>
    );
  }, [changed]);

  console.log(changed);
  return (
    <Container className='bg-primary-400 h-screen flex flex-col items-center justify-center px-4'>
      <Title color='white' className='text-xl md:text-3xl'>
        {message}
      </Title>
      <Container className='flex justify-center items-center'>{Dots}</Container>
    </Container>
  );
}
