import clsx from 'clsx';
import React from 'react';

console.log(React.version);

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  open: boolean;
}

export default function Modal({ children, open, ...props }: ModalProps) {
  const { className, ...rest } = props;

  return (
    <>
      {open && (
        <div
          className={clsx('absolute md:left-1/4 top-0 bg-white w-full md:w-1/2 md:h-screen p-20 shadow-2xl', className)}
          {...rest}
        >
          {children}
        </div>
      )}
    </>
  );
}
