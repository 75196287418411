import clsx from 'clsx';
import React from 'react';

console.log(React.version);

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  background?: 'white' | 'gray';
}

export default function Container({ children, background, ...props }: ContainerProps) {
  const { className, ...rest } = props;

  return (
    <div
      {...rest}
      className={clsx(background !== undefined && (background === 'gray' ? 'bg-secondary-100' : 'bg-white'), className)}
    >
      {children}
    </div>
  );
}
