import clsx from 'clsx';
import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';

console.log(React.version);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasIcon: boolean;
  title: string;
  active?: boolean;
}

export default function Button({ hasIcon, title, active, ...rest }: ButtonProps) {
  const { className, ...props } = rest;
  return (
    <button
      {...props}
      className={clsx(
        'flex items-center justify-center gap-2 px-4 py-3 text-white font-bold rounded-lg',
        active ? 'bg-primary-400 hover:scale-105 hover:transition-all' : 'bg-secondary-800',
        className,
      )}
    >
      {hasIcon && <IoMdArrowBack className='text-xl' />}
      {title}
    </button>
  );
}
