import clsx from 'clsx';
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { HiOutlinePhoneArrowUpRight } from 'react-icons/hi2';
import { TbWorldWww } from 'react-icons/tb';
import { TERMS_COOKIES, TERMS_POLITICA_PRIVACIDADE, TERMS_USO } from '../utils/consts';
import SubTitle from './SubTitle';

console.log(React.version);

const LINKEDIN = 'https://www.linkedin.com/company/amplar/?originalSubdomain=br';
const INSTAGRAM = 'https://www.instagram.com/amplargs/';
const FACEBOOK = 'https://www.facebook.com/amplargs/';
const SITE = 'https://www.amplargs.com.br/';
const WHATSAPP_1 = 'https://api.whatsapp.com/send?phone=5535998095042';
const WHATSAPP_2 = 'https://api.whatsapp.com/send?phone=5535999031993';
const EMAIL = 'mailto:contato@amplargs.com.br';
const MAPS = 'https://maps.app.goo.gl/nrAauSNefqHBuFbU6';
const PHONE = 'tel:+553534149913';

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Footer({ ...props }: FooterProps) {
  const { className, ...rest } = props;

  const Icon = ({ component, link }: { component: React.ReactNode; link: string }) => {
    component = React.cloneElement(component as React.ReactElement, {
      className: 'text-white text-lg w-1/6 hover:scale-110 cursor-pointer hover:transition-all hover:text-primary-400',
      onClick: () => handleOpenLink(link),
    });
    return <>{component}</>;
  };

  function handleOpenLink(link: string) {
    window.open(link, '_blank');
  }

  return (
    <div
      {...rest}
      className={clsx(
        'w-full bg-secondary-900 flex justify-center items-center flex-col md:gap-16 md:pt-16',
        className,
      )}
    >
      <div className='grid grid-cols-1 md:grid-cols-4'>
        <div className='w-full flex flex-col justify-center items-center gap-4 my-4 md:my-0'>
          <img src='../assets/img/amplarlogo2.png' alt='Amplar Logo' className='w-1/2' />
          <div className='flex justify-center gap-4'>
            <Icon component={<FaLinkedin />} link={LINKEDIN} />
            <Icon component={<FaInstagram />} link={INSTAGRAM} />
            <Icon component={<FaFacebook />} link={FACEBOOK} />
            <Icon component={<TbWorldWww />} link={SITE} />
          </div>
        </div>
        <div className='w-full flex items-center md:items-start justify-start flex-col gap-4 px-4 my-4 md:my-0'>
          <SubTitle>
            <p className='font-bold text-primary-400'>Onde Estamos</p>
          </SubTitle>
          <div className='flex gap-4'>
            <Icon component={<FiMapPin />} link={MAPS} />
            <SubTitle>
              <p className='text-white'>Rua Alcino Vicente, 55 - Vila Togni Poços de Caldas - MG - CEP: 37704-354</p>
            </SubTitle>
          </div>
        </div>
        <div className='w-full flex items-center md:items-start justify-start flex-col gap-4 px-4 my-4 md:my-0'>
          <SubTitle>
            <p className='font-bold text-primary-400'>Contato</p>
          </SubTitle>
          <div className='flex md:gap-4 w-full justify-center md:justify-normal'>
            <Icon component={<HiOutlinePhoneArrowUpRight />} link={PHONE} />
            <SubTitle>
              <p className='text-white'>(35) 3414-9913</p>
            </SubTitle>
          </div>
          <div className='flex md:gap-4 w-full justify-center md:justify-normal'>
            <Icon component={<FaWhatsapp />} link={WHATSAPP_1} />
            <SubTitle>
              <p className='text-white'>(35) 99809-5042</p>
            </SubTitle>
          </div>
          <div className='flex md:gap-4 w-full justify-center md:justify-normal'>
            <Icon component={<FaWhatsapp />} link={WHATSAPP_2} />
            <SubTitle>
              <p className='text-white'>(35) 99903-1993</p>
            </SubTitle>
          </div>
          <div className='flex md:gap-4 w-full justify-center md:justify-normal'>
            <Icon component={<HiOutlineMail />} link={EMAIL} />
            <SubTitle>
              <p className='text-white'>contato@amplargs.com.br</p>
            </SubTitle>
          </div>
        </div>
        <div className='w-full flex items-center md:items-start justify-start flex-col gap-4 px-4 my-4 md:my-0'>
          <SubTitle>
            <p className='font-bold text-primary-400'>Acesso</p>
          </SubTitle>
          <div className='w-full flex items-center md:items-start justify-start flex-col gap-4 px-4 my-4 md:my-0'>
            <SubTitle>
              <p className='text-white hover:scale-105 hover:transition-all'>
                <a href={TERMS_POLITICA_PRIVACIDADE} rel='noreferrer'>
                  Termos de Política e privacidade
                </a>
              </p>
            </SubTitle>
            <SubTitle>
              <p className='text-white hover:scale-105 hover:transition-all'>
                <a href={TERMS_COOKIES} rel='noreferrer'>
                  Termo de Política de Cookies
                </a>
              </p>
            </SubTitle>
            <SubTitle>
              <p className='text-white hover:scale-105 hover:transition-all'>
                <a href={TERMS_USO} rel='noreferrer'>
                  Termos de Uso
                </a>
              </p>
            </SubTitle>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center mb-8 md:mb-2'>
        <SubTitle>Copyright © Amplargs. (Lei 9610 de 19/02/1998)</SubTitle>
      </div>
    </div>
  );
}
