import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArrowDownButton from '../components/ArrowDownButton';
import Button from '../components/Button';
import Card from '../components/Card';
import Container from '../components/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Input from '../components/Input';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';
import { STATES } from '../utils/consts';
import { maskCnpj } from '../utils/masks';

export default function Home() {
  const [cnpj, setCnpj] = React.useState(localStorage.getItem('cnpj') || '');
  const [reason, setReason] = React.useState(localStorage.getItem('reason') || '');
  const [state, setState] = React.useState(localStorage.getItem('state') || '');

  React.useEffect(() => {
    AOS.init();
  }, []);

  function handleScrolltoBottom() {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  }

  function handleScrolltoTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleSubmit() {
    if (cnpj.length !== 18 || state === '') return;
    const localStorageCnpj = localStorage.getItem('cnpj');
    if (cnpj !== localStorageCnpj) {
      localStorage.setItem('cnpj', cnpj);
      localStorage.setItem('reason', reason);
      localStorage.setItem('state', state);
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('phone');
      localStorage.removeItem('whatsapp');
      localStorage.removeItem('access');
    }
    window.location.href = '/loading';
  }

  return (
    <>
      <Container className='md:h-screen flex flex-col justify-between px-4 md:px-0'>
        <Header />
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32'>
          <Container
            className='flex flex-col justify-center gap-8 p-4 md:p-0'
            data-aos='fade-right'
            data-aos-duration='1000'
          >
            <Title color='green' className='text-center md:text-start'>
              Faça sua Consulta de Licença Ambiental gratuita
            </Title>
            <SubTitle className='text-center md:text-start mt-4 md:mt-0'>
              Verifique rapidamente a situação do licenciamento ambiental da sua empresa. É fácil e grátis!
            </SubTitle>
            <Container className='flex flex-col md:gap-2'>
              <Input
                placeholder='Digite o CNPJ da sua empresa'
                inputType='large'
                className='mt-2 md:mt-0'
                onChange={e => {
                  e.currentTarget.value = maskCnpj(e.currentTarget.value);
                  setCnpj(e.currentTarget.value);
                }}
                type='text'
                name='cnpj'
                value={cnpj}
              />
              <Input
                placeholder='Motivo da consulta'
                inputType='large'
                className='mt-2 md:mt-0'
                onChange={e => setReason(e.currentTarget.value)}
                type='text'
                name='reason'
                value={reason}
              />
              <select
                className='rounded-lg mt-2 h-16 bg-secondary-100 text-center text-xl'
                name='state'
                id='state'
                required
                onChange={e => setState(e.currentTarget.value)}
                value={state}
              >
                <option value='' disabled selected>
                  Estado
                </option>
                {STATES.map(state => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </Container>
            <Button
              active={cnpj.length === 18 && state !== ''}
              className='mt-4 md:mt-0'
              hasIcon={false}
              title='Consultar'
              onClick={handleSubmit}
            />
          </Container>
          <Container className='flex items-center justify-center'>
            <img
              src='../assets/img/ilustrations/technic.png'
              alt='Home'
              className='w-3/4'
              data-aos='fade-left'
              data-aos-duration='1500'
            />
          </Container>
        </Container>
        <Container className='md:pl-32 md:pb-10 hidden md:block'>
          <ArrowDownButton onClick={handleScrolltoBottom} />
        </Container>
      </Container>
      <Container background='white'>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4 px-4'>
          <Title color='green' className='text-center md:text-start'>
            Por que realizar a consulta de licença ambiental?
          </Title>
        </Container>
        <Container className='pt-4 md:pt-8 md:px-32'>
          <Carousel className='w-full flex md:hidden' showArrows={true} showStatus={false} showThumbs={false}>
            <Card
              image='conformidade'
              title='Conformidade'
              subtitle='Garanta que sua empresa está em conformidade com as regulamentações ambientais, evitando multas e sanções.'
            />
            <Card
              image='transparencia'
              title='Transparência e Credibilidade'
              subtitle='Mostre compromisso com a sustentabilidade para clientes, parceiros e investidores.'
            />
            <Card
              image='identificacao'
              title='Identificação de Riscos'
              subtitle='Detecte e resolva problemas ambientais precocemente, minimizando impactos negativos.'
            />
            <Card
              image='melhoria'
              title='Melhoria Contínua'
              subtitle='Utilize os dados da consulta para implementar práticas ambientais mais eficientes.'
            />
            <Card
              image='vantagem'
              title='Vantagem Competitiva'
              subtitle='Atraia clientes e investidores que valorizam a responsabilidade ambiental.'
            />
            <Card
              image='apoio'
              title='Apoio Técnico Especializado'
              subtitle='Receba orientação de especialistas em meio ambiente para resolver dúvidas e tomar decisões informadas.'
            />
          </Carousel>
          <Carousel className='w-full hidden md:flex' showArrows={true} showStatus={false} showThumbs={false}>
            <Container className='flex'>
              <Card
                image='conformidade'
                title='Conformidade'
                subtitle='Garanta que sua empresa está em conformidade com as regulamentações ambientais, evitando multas e sanções.'
              />
              <Card
                image='transparencia'
                title='Transparência e Credibilidade'
                subtitle='Mostre compromisso com a sustentabilidade para clientes, parceiros e investidores.'
              />
              <Card
                image='identificacao'
                title='Identificação de Riscos'
                subtitle='Detecte e resolva problemas ambientais precocemente, minimizando impactos negativos.'
              />
            </Container>
            <Container className='flex'>
              <Card
                image='melhoria'
                title='Melhoria Contínua'
                subtitle='Utilize os dados da consulta para implementar práticas ambientais mais eficientes.'
              />
              <Card
                image='vantagem'
                title='Vantagem Competitiva'
                subtitle='Atraia clientes e investidores que valorizam a responsabilidade ambiental.'
              />
              <Card
                image='apoio'
                title='Apoio Técnico Especializado'
                subtitle='Receba orientação de especialistas em meio ambiente para resolver dúvidas e tomar decisões informadas.'
              />
            </Container>
          </Carousel>
        </Container>
      </Container>
      <Container className='flex flex-col justify-between md:mt-4 md:py-8 md:grid md:grid-cols-2 md:px-32 md:gap-32 px-4'>
        <Container
          className='flex flex-col justify-center gap-8 p-4 md:p-0'
          data-aos='fade-right'
          data-aos-duration='1000'
        >
          <Title color='green' className='text-center md:text-start'>
            Como funciona a consulta de licença ambiental na Amplar?
          </Title>
          <SubTitle className='text-center md:text-start my-6 md:mt-0 leading-6'>
            A consulta de licença ambiental na Amplar GS é um processo simples e rápido, projetado para fornecer a você
            todas as informações necessárias sobre a situação do licenciamento ambiental da sua empresa. Basta digitar o
            CNPJ da sua empresa no campo indicado e adicionar o motivo da consulta para que possamos personalizar o
            atendimento. Em seguida, complete o reCAPTCHA para garantir a segurança da operação.
          </SubTitle>
          <Button
            active
            className='mt-4 md:mt-0'
            hasIcon={false}
            title='Realizar Consuta'
            onClick={handleScrolltoTop}
          />
        </Container>
        <Container className='flex items-center justify-center mt-6 md:mt-0'>
          <img
            src='../assets/img/ilustrations/howitworks.png'
            alt='Home'
            className='w-3/4'
            data-aos='fade-left'
            data-aos-duration='1500'
          />
        </Container>
      </Container>
      <Container
        background='gray'
        className='flex flex-col-reverse justify-between md:py-8 my-8 md:grid md:grid-cols-2 md:px-32 md:gap-32 px-4'
      >
        <Container className='flex items-center justify-center mt-6 md:mt-0'>
          <img
            src='../assets/img/ilustrations/ourservices.png'
            alt='Home'
            className='w-3/4'
            data-aos='fade-right'
            data-aos-duration='1000'
          />
        </Container>
        <Container
          className='flex flex-col justify-center gap-8 p-4 md:p-0'
          data-aos='fade-left'
          data-aos-duration='1500'
        >
          <Title color='green' className='text-center md:text-start'>
            Conheça os nossos serviços
          </Title>
          <SubTitle className='text-center md:text-start my-6 md:mt-0 leading-6'>
            Na Amplar, oferecemos uma gama completa de serviços voltados para a sustentabilidade e a conformidade
            ambiental. Nossa equipe de especialistas está pronta para ajudar sua empresa a implementar práticas
            ambientais eficazes e inovadoras. Desde consultoria e treinamentos até o desenvolvimento de soluções
            personalizadas, estamos aqui para apoiar todas as suas necessidades ambientais. Descubra como nossos
            serviços podem ajudar sua empresa a prosperar de forma sustentável e responsável.{' '}
          </SubTitle>
          <Button
            active
            className='mt-4 md:mt-0'
            hasIcon={false}
            title='Realizar Consuta'
            onClick={handleScrolltoTop}
          />
        </Container>
      </Container>
      <Container background='white' className='px-4 md:px-0'>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4'>
          <Title color='green' className='text-center md:text-start py-4' data-aos='fade-right' data-aos-duration='500'>
            Perguntas Frequentes
          </Title>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1000'
        >
          <SubTitle className='font-bold'>1. O que é uma licença ambiental?</SubTitle>
          <SubTitle className='pt-2'>
            A licença ambiental é um documento que autoriza e regulariza a operação de atividades potencialmente
            poluidoras, garantindo que estejam em conformidade com as normas ambientais.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1500'
        >
          <SubTitle className='font-bold'>2. Por que devo consultar a licença ambiental da minha empresa?</SubTitle>
          <SubTitle className='pt-2'>
            Consultar a licença ambiental ajuda a identificar possíveis não conformidades e a implementar melhorias
            contínuas, assegurando a sustentabilidade e legalidade das operações.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>3. Quanto tempo leva para obter o resultado da consulta?</SubTitle>
          <SubTitle className='pt-2'>
            A consulta é realizada de forma rápida e o resultado é disponibilizado imediatamente após a verificação do
            CNPJ.{' '}
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2500'
        >
          <SubTitle className='font-bold'>4. A consulta é realmente gratuita?</SubTitle>
          <SubTitle className='pt-2'>
            Sim, a consulta de licença ambiental na Amplar é completamente gratuita e caso você tenha pendências ou
            precise de algum serviço para se regularizar você pode entrar em contato com nosso time de especialista para
            te ajudar na regularização.
          </SubTitle>
        </Container>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
          <Button active title='CLIQUE AQUI PARA REALIZAR A CONSULTA' hasIcon={false} onClick={handleScrolltoTop} />
        </Container>
      </Container>
      <Footer />
    </>
  );
}
