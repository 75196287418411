import clsx from 'clsx';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import { MdOutlineHelpOutline } from 'react-icons/md';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import Container from '../components/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Input from '../components/Input';
import Modal from '../components/Modal';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';
import { ABREVIATIONS, SERVER_URL, TERMS_POLITICA_PRIVACIDADE, TERMS_USO } from '../utils/consts';
import { maskPhone } from '../utils/masks';
import { Lead, LeadResponse, License } from '../utils/types';

export default function Result() {
  const data: { cnpj: string; licenses: License[] } = JSON.parse(localStorage?.getItem('data') ?? '[]');
  const { licenses } = data;
  const reason = localStorage.getItem('reason') || '';
  const cnpj = localStorage.getItem('cnpj') || '';

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [dataComplete, setDataComplete] = React.useState<boolean>(false);
  const [hasData, setHasData] = React.useState<boolean>(localStorage.getItem('access') === 'true');
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);

  const [name, setName] = React.useState<string>(localStorage.getItem('name') || '');
  const [email, setEmail] = React.useState<string>(localStorage.getItem('email') || '');
  const [phone, setPhone] = React.useState<string>(localStorage.getItem('phone') || '');
  const [whatsapp, setWhatsapp] = React.useState<string>(localStorage.getItem('whatsapp') || '');
  const [numberIsWhatsapp, setNumberIsWhatsapp] = React.useState<boolean>(false);

  const [openHelp, setOpenHelp] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!licenses.length) {
      alert('Nenhuma licença encontrada localmente! informe o CNPJ novamente.');
      window.location.href = '/';
    }
  }, []);

  React.useEffect(() => {
    const nameComplete = name.length >= 3 && name.length < 100;
    const emailComplete = email.length > 8 && email.includes('@') && email.includes('.') && email.length < 100;
    const phoneComplete = phone.length > 11;
    const whatsappComplete = whatsapp.length > 11;
    setDataComplete(nameComplete && emailComplete && phoneComplete && whatsappComplete && acceptTerms);
  }, [name, email, phone, whatsapp, acceptTerms]);

  const TBodyLines = React.useMemo(
    () =>
      licenses.map((license, index) => (
        <tr
          key={index}
          className={clsx('border-b-2 border-white bg-secondary-100', !hasData && index > 0 && 'blur-sm')}
        >
          <td className='text-center px-1 border-r-2 border-white hidden md:table-cell'>
            <SubTitle>{hasData || index === 0 ? license.doc_number : '0000000'}</SubTitle>
          </td>
          <td
            className={clsx(
              'text-sm text-center px-1 border-r-2 border-white',
              license.is_valid ? 'text-primary-400' : 'text-red-500 font-bold',
            )}
          >
            {hasData || index === 0 ? license.end_date : license.end_date ? '00/00/0000' : ''}
          </td>
          <td className='text-center px-1 border-r-2 border-white'>
            <SubTitle>
              {hasData || index === 0 ? ABREVIATIONS[convertLicense(license)] ?? license?.license_type : 'AAAA'}
            </SubTitle>
          </td>
          <td className='text-center px-1 border-r-2 border-white'>
            <SubTitle>{hasData || index === 0 ? license?.logradouro : 'Favor preencher os campos!'}</SubTitle>
          </td>
          <td className='text-center px-1 border-r-2 border-white hidden md:table-cell'>
            <SubTitle>{hasData || index === 0 ? license.since_date : '00/00/0000'}</SubTitle>
          </td>
          <td className='text-center px-1 border-r-2 border-white hidden md:table-cell'>
            <SubTitle>{hasData || index === 0 ? license.situation : 'Favor preencher os campos!'}</SubTitle>
          </td>
          <td className='text-center px-1 border-r-2 border-white'>
            <a
              href={hasData || index === 0 ? license.url : '#'}
              target={hasData || index === 0 ? '_blank' : '_self'}
              rel='noreferrer'
              className={clsx(!license.url && 'hidden')}
            >
              <FaDownload className='text-primary-400 text-center hover:scale-110 hover:transition-all w-full' />
            </a>
          </td>
        </tr>
      )),
    [licenses, hasData],
  );

  async function handleAccess() {
    if (!dataComplete) {
      alert('Preencha todos os campos!');
      return;
    }

    const data: Lead = { name, email, phone, reason, whatsapp, cnpj };
    await fetch(`${SERVER_URL}/leads`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(async (res: LeadResponse) => {
        alert(res.message);
        if (res.status === 'error') return;
        setHasData(true);
        setOpenModal(false);
        localStorage.setItem('access', 'true');
      })
      .catch(error => {
        console.error(error);
        alert('Erro ao liberar acesso!');
      });
  }

  function handleChangesName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
    localStorage.setItem('name', e.target.value);
  }
  function handleChangesEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    localStorage.setItem('email', e.target.value);
  }
  function handleChangesPhone(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = maskPhone(e.target.value);
    setPhone(e.target.value);
    localStorage.setItem('phone', e.target.value);
  }
  function handleChangesWhatsapp(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = maskPhone(e.target.value);
    setWhatsapp(e.target.value);
    localStorage.setItem('whatsapp', e.target.value);
  }
  function countLicensesNotValid() {
    const count = licenses.filter(license => !license.is_valid && license.end_date).length;
    if (count > 0 && !hasData) return 'Foram encontradas ' + count + ' licenças vencidas!';
  }

  function convertLicense(license: License) {
    return license?.license_type
      .toLowerCase()
      .replace('licenca', 'licença')
      .replace('operacao', 'operação')
      .replace('instalacao', 'instalação')
      .replace('previa', 'prévia')
      .replace('instalaçao', 'instalação')
      .replace('instalacão', 'instalação');
  }

  return (
    <>
      <Header />
      <Container
        className='px-4 md:px-32 flex flex-col-reverse md:flex-col gap-8 pt-8 mb-24'
        onClick={() => {
          if (openModal) setOpenModal(false);
          if (openHelp) setOpenHelp(false);
        }}
      >
        <Container className='flex md:justify-between md:items-center'>
          <Button title='FAZER OUTRA CONSULTA' onClick={() => (window.location.href = '/')} hasIcon active />
          <Title className='font-bold mb-4 hidden md:flex'>
            <span className='text-red-500'>{countLicensesNotValid()}</span>
          </Title>
        </Container>
        <Container>
          <SubTitle className='font-bold mb-4'>Resultados da Consulta</SubTitle>
          <SubTitle className='font-bold mb-4 flex md:hidden'>
            <span className='text-red-500'>{countLicensesNotValid()}</span>
          </SubTitle>
          <Container className='bg-secondary-900 flex items-center justify-center py-2'>
            <SubTitle className='text-secondary-100'>Documentos disponíveis</SubTitle>
          </Container>
          <table
            className='w-full mb-8 relative'
            style={
              !hasData
                ? { WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }
                : {}
            }
          >
            <thead>
              <tr className='border-b-8 border-t-8 bg-secondary-100 border-white'>
                <th className='text-center px-2 border-r-2 border-white hidden md:table-cell'>
                  <SubTitle>Nº do Doc</SubTitle>
                </th>
                <th className='text-center px-2 border-r-2 border-white'>
                  <SubTitle>Validade</SubTitle>
                </th>
                <th className='text-center px-2 border-r-2 border-white'>
                  <div className='flex items-center justify-center gap-1'>
                    <SubTitle>Tipo</SubTitle>
                    <MdOutlineHelpOutline
                      className='text-primary-400 animate-pulse md:text-2xl hover:scale-150 hover:transition-all cursor-pointer'
                      onClick={() => setOpenHelp(true)}
                    />
                  </div>
                </th>
                <th className='text-center px-2 border-r-2 border-white'>
                  <SubTitle>Logradouro</SubTitle>
                </th>
                <th className='text-center px-2 border-r-2 border-white hidden md:table-cell'>
                  <SubTitle>Data da Solicitação</SubTitle>
                </th>
                <th className='text-center px-2 border-r-2 border-white  hidden md:table-cell'>
                  <SubTitle>Situação</SubTitle>
                </th>
                <th className='text-center px-2 border-r-2 border-white'>
                  <SubTitle className='hidden md:block'>Download</SubTitle>
                </th>
              </tr>
            </thead>
            <Button
              onClick={() => {
                setOpenModal(true);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              title='Clique aqui para ver mais!'
              hasIcon={false}
              active
              className={clsx('absolute z-10 top-24 w-44 left-[50%] transform -translate-x-1/2', hasData && 'hidden')}
            />
            <tbody>{TBodyLines}</tbody>
          </table>
        </Container>
      </Container>
      <Modal open={openModal} className='z-40 flex flex-col justify-between'>
        <IoIosClose
          className={clsx('absolute top-4 right-4 text-primary-400 cursor-pointer text-[64px]')}
          onClick={() => setOpenModal(false)}
        />
        <Title color='green'>Falta pouco para ter acesso completo</Title>
        <SubTitle>
          Preencha os campos abaixo para acessar mais informações e ter detalhes sobre a situação da sua empresa.
        </SubTitle>
        <Container className='flex flex-col gap-2'>
          <SubTitle className='font-bold'>Nome*</SubTitle>
          <Input
            value={name}
            onChange={handleChangesName}
            inputType='small'
            name='name'
            placeholder='Digite aqui'
            className='text-start pl-4'
          />
        </Container>
        <Container className='flex flex-col gap-2'>
          <SubTitle className='font-bold'>Email*</SubTitle>
          <Input
            value={email}
            onChange={handleChangesEmail}
            inputType='small'
            name='email'
            placeholder='seuemail@email.com'
            className='text-start pl-4'
          />
        </Container>
        <Container className='flex flex-col gap-2'>
          <SubTitle className='font-bold'>Telefone*</SubTitle>
          <Input
            value={phone}
            onChange={handleChangesPhone}
            inputType='small'
            name='phone'
            placeholder='(00) 00000-0000'
            className='text-start pl-4'
          />
        </Container>
        <Container className='flex flex-col gap-2'>
          <Container className='flex gap-2'>
            <SubTitle className='font-bold'>Whatsapp*</SubTitle>
            <Checkbox
              checked={numberIsWhatsapp}
              onClick={() => {
                if (numberIsWhatsapp) setWhatsapp('');
                else setWhatsapp(phone);
                setNumberIsWhatsapp(!numberIsWhatsapp);
              }}
            />
            <SubTitle className='text-sm'>O número informado é whatsapp?</SubTitle>
          </Container>
          <Input
            value={whatsapp}
            onChange={handleChangesWhatsapp}
            inputType='small'
            name='phone'
            placeholder='(00) 00000-0000'
            className='text-start pl-4'
          />
        </Container>
        <Container className='flex gap-2'>
          <Checkbox checked={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} />
          <SubTitle>
            <span>Ao informar meus dados, eu concordo com a </span>
            <a target='_blank' href={TERMS_POLITICA_PRIVACIDADE} className='text-primary-400' rel='noreferrer'>
              Política de Privacidade
            </a>
            <span> e com os </span>
            <a target='_blank' href={TERMS_USO} className='text-primary-400' rel='noreferrer'>
              Termos de Uso
            </a>
          </SubTitle>
        </Container>
        <Button title='Liberar Acesso' onClick={handleAccess} hasIcon={false} active={dataComplete} />
      </Modal>
      <Modal open={openHelp} className='z-40 flex flex-col justify-between items-center'>
        <IoIosClose
          className={clsx('absolute top-4 right-4 text-primary-400 cursor-pointer text-[64px]')}
          onClick={() => setOpenHelp(false)}
        />
        <Title color='green' className='text-center'>
          Tipos de Licenças
        </Title>
        <Container className='w-full flex flex-col gap-4 mt-8'>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>LO: </strong>Licença de Operação
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>LI: </strong>Licença de Instalação
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>CADRI: </strong>Certificado de Movimentação de Resíduos de Interesse Ambiental
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>LPI: </strong>Licença Prévia e de Instalação
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>CDL: </strong>Certificado de Dispensa de Licença
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>PT: </strong>Parecer Técnico
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>ALT: </strong>Alteração de Documento
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>LP: </strong>Licença Prévia
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>PD: </strong>Parecer Desfavorável
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>LPIO: </strong>Licença Prévia, de Instalação e de Operação
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>CEEI: </strong>Certificado de Enquadramento de Estabelecimento Industrial
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>TCI: </strong>Treinamento de combate a incêndio
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>DAIL: </strong>Declaração de Atividade Isenta de Licenciamento
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>VRA: </strong>Autorização emitida pelo VRA
            </SubTitle>
          </Container>
          <Container className='flex w-full rounded-lg md:py-1 justify-center' background='gray'>
            <SubTitle className='text-center'>
              <strong>AI: </strong>Auto de Infração
            </SubTitle>
          </Container>
        </Container>
      </Modal>
      <Footer />
    </>
  );
}
