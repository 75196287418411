import clsx from 'clsx';
import React from 'react';
import { FaCheck } from 'react-icons/fa';

console.log(React.version);

interface CheckboxProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
}
export default function Checkbox({ checked, ...props }: CheckboxProps) {
  const { className, ...rest } = props;
  return (
    <button
      className={clsx(
        'w-6 h-5 border-2 border-secondary-800 rounded-sm hover:border-4 flex justify-center items-center',
        checked ? 'bg-primary-400' : 'bg-secondary-100',
        className,
      )}
      {...rest}
    >
      {checked && <FaCheck className='text-white text-sm' />}
    </button>
  );
}
