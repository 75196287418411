import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Button from '../components/Button';
import Container from '../components/Container';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';

export default function Termos() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  function goToHome() {
    window.location.href = '/';
  }

  return (
    <>
      <Header />
      <Container background='white' className='px-4 md:px-0'>
        <Container className='md:grid md:grid-cols-5 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
          <Button active title='Voltar' hasIcon={false} onClick={goToHome} />
        </Container>
        <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4'>
          <Title color='green' className='text-center md:text-start py-4' data-aos='fade-right' data-aos-duration='500'>
            Termos de Uso da Página de Consulta de Licenças Ambientais
          </Title>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1000'
        >
          <SubTitle className='font-bold'>1. Aceitação dos Termos</SubTitle>
          <SubTitle className='pt-2'>
            Ao acessar e utilizar a página de consulta de licenças ambientais <u>licenciamento.amplargs.com.br</u>, você
            concorda com os seguintes Termos de Uso. Se você não concorda com algum destes termos, por favor, não
            utilize a Página.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='1500'
        >
          <SubTitle className='font-bold'>2. Serviços Oferecidos</SubTitle>
          <SubTitle className='pt-2'>
            A Página permite que empresas consultem o status de suas licenças ambientais utilizando dados fornecidos. A
            consulta é destinada a auxiliar as empresas na verificação de conformidade com as exigências ambientais.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>3. Coleta e Uso de Dados</SubTitle>
          <SubTitle className='pt-2'>
            Ao utilizar a Página, você poderá fornecer informações como telefone e e-mail. Ao submeter esses dados, você
            consente que sejam utilizados para os seguintes propósitos: envio de informações e atualizações sobre o
            status de suas licenças ambientais, contato para oferecer serviços relacionados à licença ambiental,
            incluindo consultoria e suporte técnico.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2500'
        >
          <SubTitle className='font-bold'>4. Privacidade</SubTitle>
          <SubTitle className='pt-2'>
            Respeitamos sua privacidade e nos comprometemos a proteger seus dados pessoais. As informações coletadas
            serão tratadas conforme nossa Política de Privacidade, disponível na Página.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>5. Responsabilidade pelo Uso dos Dados</SubTitle>
          <SubTitle className='pt-2'>
            A empresa não se responsabiliza pela precisão, integridade ou validade dos dados fornecidos por terceiros ou
            por quaisquer erros na consulta do status das licenças ambientais. A responsabilidade pela verificação e
            conformidade com as exigências ambientais é inteiramente da empresa usuária.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>6. Alterações nos Termos de Uso</SubTitle>
          <SubTitle className='pt-2'>
            Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Quaisquer alterações serão
            publicadas nesta Página, e é de sua responsabilidade revisar estes termos regularmente para garantir que
            você está ciente de qualquer modificação.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>7. Isenção de Garantias e Limitação de Responsabilidade</SubTitle>
          <SubTitle className='pt-2'>
            A Página é fornecida “como está” e “conforme disponível”, sem garantias de qualquer tipo, expressas ou
            implícitas. Em nenhuma circunstância, a empresa será responsável por quaisquer danos diretos, indiretos,
            incidentais, consequenciais ou punitivos resultantes do uso ou da incapacidade de uso da Página.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>8. Lei Aplicável</SubTitle>
          <SubTitle className='pt-2'>
            Estes Termos de Uso são regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa decorrente
            ou relacionada a estes termos será resolvida exclusivamente nos tribunais competentes do Brasil.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>9. Contato</SubTitle>
          <SubTitle className='pt-2'>
            Para quaisquer dúvidas ou questões sobre estes Termos de Uso, entre em contato conosco através do e-mail
            contato@amplargs.com.br ou pelo telefone (35) 3414-9913.
          </SubTitle>
        </Container>
        <Container
          className='p-4 md:pt-8 md:mx-32 w-auto rounded-lg mb-4'
          background='gray'
          data-aos='fade-right'
          data-aos-duration='2000'
        >
          <SubTitle className='font-bold'>10. Aceitação dos Termos</SubTitle>
          <SubTitle className='pt-2'>
            Ao clicar em “Aceito” ou ao utilizar a Página, você reconhece que leu, entendeu e concorda em estar
            vinculado a estes Termos de Uso.
          </SubTitle>
        </Container>
      </Container>
      <Container className='md:grid md:grid-cols-2 md:px-32 md:gap-32 pt-4 pb-10 justify-center flex'>
        <Button active title='CLIQUE AQUI PARA REALIZAR A CONSULTA' hasIcon={false} onClick={goToHome} />
      </Container>
      <Footer />
    </>
  );
}
